import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import ListTaxa from './ListTaxa.js';

const DropdownSearch = ({ onDocumentClick, collectionName, itemLabelKey, id, placeholder, defaultValue, direction }) => {
  const [documentName, setDocumentName] = useState('');
  const [results, setResults] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [fetchingResults, setFetchingResults] = useState(false);
  const searchTimeout = useRef(null);

  useEffect(() => {
    if (defaultValue) {
      setDocumentName(defaultValue);
    }
  }, [defaultValue]);

  const getDocuments = (name) => {
    console.log(`/api/${collectionName}?${itemLabelKey}=${name}`)
    axios
      .get(`/api/${collectionName}?${itemLabelKey}=${name}`)
      .then((res) => {
        if (res.data) {
          const sortedDocuments = res.data.sort((a, b) => {
            const aLabel = a[itemLabelKey].toLowerCase();
            const bLabel = b[itemLabelKey].toLowerCase();
            const lowercasedName = name.toLowerCase();
          
            // Consider exact match
            if (aLabel === lowercasedName) {
              return -2;
            } else if (bLabel === lowercasedName) {
              return 2;
            }
          
            // Compare by the length of the matched prefix
            const aPrefixLength = aLabel.startsWith(lowercasedName) ? lowercasedName.length : 0;
            const bPrefixLength = bLabel.startsWith(lowercasedName) ? lowercasedName.length : 0;
          
            // Sort by prefix length in ascending order
            if (aPrefixLength > bPrefixLength) {
              return -1;
            } else if (aPrefixLength < bPrefixLength) {
              return 1;
            }
          
            // Default sorting based on label
            return aLabel.localeCompare(bLabel);
          });

          setResults(sortedDocuments);
          setFetchingResults(false);
          setDropdownOpen(name !== '');
        }
      })
      .catch((err) => console.log(err));
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setFetchingResults(!Boolean(results.length));
    setDocumentName(value);
    clearTimeout(searchTimeout.current);
    searchTimeout.current = setTimeout(() => {
      getDocuments(value);
    }, 250);
    if (value !== '') {
      setDropdownOpen(true);
    }
  };

  return (
    <>
      <input
        type="text"
        className="form-control"
        id={id}
        name="name"
        placeholder={placeholder ?? "Search..."}
        onChange={handleChange}
        value={documentName || ''}
        autoComplete="off"
        style={{border: "1px solid #d3d3d3"}}
      />
      <Dropdown direction={direction} isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)} style={{marginLeft: '20px'}}>
        <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen} />
        <DropdownMenu style={{marginBottom: direction === 'up' ? '50px' : 0}}>
          <ListTaxa 
            items={results}
            fetchingResults={fetchingResults}
            onItemSelect={(selectedItem) => {
              onDocumentClick(selectedItem);
              setDocumentName(selectedItem[itemLabelKey]);
              setDropdownOpen(false);
            }}
            itemLabelKey={itemLabelKey}
          />
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default DropdownSearch;
