// isAuth.js
import axios from 'axios';

export const checkAuthStatus = async () => {
    return await axios.get('/check-auth')
      .then(response => {
        return response.data.isAuthenticated
      })
      .catch(function (error) {
        console.error(error.message)
      });
};