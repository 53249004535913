import emailjs from "@emailjs/browser";
import { useEffect, useRef } from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";

const EmailContact = () => {
    const form = useRef(null);

    useEffect(() => {
        emailjs.init("4dDvgKsBgyz01quuq");
    }, []);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm("service_bqydzpo", "template_lf2mxsx", form.current)
            .then(
                () => {
                    console.log('SUCCESS!');
                    form.current.reset();
                },
                (error) => {
                    console.log('FAILED...', error);
                },
            );
    }

    return (
        <Form className="dark-text" innerRef={form} onSubmit={sendEmail}>
            <FormGroup>
                <Label for="user_name">Name</Label>
                <Input type="text" name="user_name" id="user_name" placeholder="Your Name" autoComplete="off" />
            </FormGroup>
            <FormGroup>
                <Label for="user_email">Email</Label>
                <Input type="email" name="user_email" id="user_email" placeholder="Your Email Address" autoComplete="off" />
            </FormGroup>
            <FormGroup>
                <Label for="message">Message</Label>
                <Input type="textarea" name="message" id="message" placeholder="Your Message" autoComplete="off" />
            </FormGroup>
            <Button color="primary" style={{ display: "block", marginLeft: "auto", marginRight: "auto", marginTop: "20px" }}>Send Message</Button>
        </Form>
    );
};

export default EmailContact;