/*!

=========================================================
* Paper Dashboard React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Card,
  CardBody,
} from "reactstrap";
import Helmet from "react-helmet";
import TaxoDaddy from "../assets/img/taxo-daddy.png";


function NotFound() {
  return (
    <>
      <Helmet>
        <title>Page Not Found | TaxoKeys</title>
      </Helmet>
      <div className="content" style={{display: "flex", justifyContent: 'center', alignItems: 'center' }}>
        <Card style={{ display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: 'center', width: '30rem', height: '11rem' }}>
          <img src={TaxoDaddy} alt="" style={{ width: '201px', height: '114px' }} />
          <CardBody style={{ display: "flex", flexDirection: "column", alignItems: 'center', flex: "0 0 auto", marginLeft: "1rem"}}>
            <h2 style={{marginBottom: 0}}>Error 404</h2>
            Page not found.
          </CardBody>
        </Card>
      </div>
    </>
  );
}


export default NotFound;
