import axios from "axios";
import { useEffect, useState } from "react";
import Loading from "../Helpers/Loading";

const { Input, ListGroup, ListGroupItem, CardBody, CardFooter, Button } = require("reactstrap");

const DBSearch = ({handleTaxonClick, initialSearch}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedTaxonName, setSelectedTaxonName] = useState(null);

    const [allTaxa, setAllTaxa] = useState([]);
    const [filteredTaxa, setFilteredTaxa] = useState([]);
    const [displayedTaxa, setDisplayedTaxa] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(0);
    const itemsPerPage = 9;

    useEffect(() => {
        const fetchTaxa = async () => {
            try {
            const response = await axios.get('/api/taxa');
            setAllTaxa(response.data || []);
            } catch (error) {
            console.error("Failed to fetch taxa:", error);
            } finally {
            setIsLoading(false);
            }
        };
  
        fetchTaxa();
    }, [])

    useEffect(() => {
        const filtered = searchTerm
          ? allTaxa.filter(taxon => taxon.taxon_name.toLowerCase().includes(searchTerm.toLowerCase()))
          : allTaxa
    
        setFilteredTaxa(filtered)    
    
        setDisplayedTaxa(filtered.slice(page * itemsPerPage, (page + 1) * itemsPerPage));
    }, [searchTerm, allTaxa, page]);
    
    const handlePreviousPage = () => {
    setPage(prevPage => Math.max(prevPage - 1, 0));
    };

    useEffect(() => {
      setSearchTerm(initialSearch)
    }, [initialSearch]);

    const handleNextPage = () => {
    setPage(prevPage => Math.min(prevPage + 1, Math.ceil(allTaxa.length / itemsPerPage) - 1));
    };

    const getPageCount = (totalItems, itemsPerPage) => {
    const pageCount = Math.ceil(totalItems / itemsPerPage);
    return pageCount > 99 ? "99+" : pageCount;
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setPage(0); // Reset to first page with new filter
    };

    const colorMapping = {
      'species': '#1cf',
      'genus': '#2c1',
      'family': '#f44',
      'order': '#e8f',
    }

    return (
      <>
        <CardBody className="search-body">
          <Input
            type="search"
            name="taxon_name"
            placeholder="Search taxa..."
            onChange={handleSearchChange}
            value={searchTerm}
            style={{ marginBottom: '15px', padding: '10px', borderRadius: '8px', border: '1px solid #ced4da' }}
          />
          <div style={{ flex: 1, overflowY: "auto", position: "relative" }}>
            {isLoading ? (
              <Loading className="loading" />
            ) : (
              <ListGroup style={{ overflowY: 'auto' }}>
                {displayedTaxa.map((taxon, index) => (
                  <ListGroupItem
                    key={taxon.taxon_id}
                    tag="a"
                    href="#"
                    action
                    onClick={(e) => {
                      e.preventDefault();
                      handleTaxonClick(taxon);
                      setSelectedTaxonName(taxon.taxon_name);
                    }}
                    className={selectedTaxonName === taxon.taxon_name ? 'active' : ''}
                    style={{
                      backgroundColor: index % 2 === 0 ? '#f8f9fa' : '#e9ecef',
                      padding: '12px 18px',
                      borderRadius: '4px',
                      marginBottom: '8px',
                      fontWeight: selectedTaxonName === taxon.taxon_name ? 'bold' : 'normal',
                      boxShadow: selectedTaxonName === taxon.taxon_name ? '0 4px 8px rgba(0, 0, 0, 0.1)' : 'none',
                      borderLeft: selectedTaxonName === taxon.taxon_name ? '4px solid #fbc658' : '',
                      borderColor: selectedTaxonName === taxon.taxon_name ? '#fbc658' : '',
                      borderTopWidth: '1px',
                      transition: 'all 0.2s ease',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <span>
                      {taxon.taxon_name.charAt(0).toUpperCase() + taxon.taxon_name.slice(1).toLowerCase()}
                    </span>
                    <span
                      style={{
                        backgroundColor: colorMapping[taxon.taxon_rank] || '#000', // You can choose a color that fits the design
                        color: '#fff',
                        padding: '3px 8px',
                        marginLeft: '10px',
                        borderRadius: '4px',
                        fontSize: '0.875em',
                        fontWeight: 'bold',
                      }}
                    >
                      {taxon.taxon_rank}
                    </span>
                  </ListGroupItem>
                ))}
              </ListGroup>
            )}
          </div>
        </CardBody>
        <CardFooter style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <Button className="btn-secondary" onClick={handlePreviousPage} disabled={page === 0} style={{ width: "12em" }}>
            Previous Page
          </Button>
          <p style={{margin: "0px", textAlign: "center", minWidth: "60px"}}>{filteredTaxa.length !== 0 ? page + 1 : 0} / {getPageCount(filteredTaxa.length, itemsPerPage)}</p>
          <Button className="btn-secondary" onClick={handleNextPage} disabled={(page + 1) * itemsPerPage >= filteredTaxa.length} style={{ width: "12em" }}>
            Next Page
          </Button>
        </CardFooter>
      </>
    )
}

export default DBSearch;