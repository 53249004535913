/*!

=========================================================
* Paper Dashboard React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate, useLocation } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/paper-dashboard.scss?v=1.3.0";
import "./assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import AdminLayout from "./layouts/Admin.js";
import SearchPage from "./pages/SearchTaxaPage";
import KeysPage from "./pages/GenerateKeyPage";
import Login from "./pages/LoginPage";
import Logout from './pages/LogoutPage'; // Adjust the import path as needed
import AddTaxonPage from "./pages/AddTaxonPage"

const isSocketIoPath = (pathname) => pathname.startsWith('/socket.io');

const MainApp = () => {
  const location = useLocation();
  
  // Check if the current path is a Socket.IO path
  if (isSocketIoPath(location.pathname)) {
    return null; // Don't render anything for Socket.IO paths
  }

  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/home" />} />
      <Route path="/*" element={<AdminLayout />}>
        <Route path="add-taxon" element={<AddTaxonPage />} />
        <Route path="search" element={<SearchPage />} />
        <Route path="generate-key" element={<KeysPage />} />
        <Route path="login-page" element={<Login />} />
        <Route path="logout" element={<Logout />} />
        {/* Route to catch paths that don't exist after /home */}
        <Route path="*" element={<Navigate replace to="/home" />} />
      </Route>
      <Route path="*" element={<Navigate replace to="/home" />} />
    </Routes>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <MainApp />
  </BrowserRouter>
);