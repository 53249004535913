import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Loading from '../Helpers/Loading';

const INaturalistWidget = ({ taxon_name, cardHeight }) => {
    const [iNaturalistId, setINaturalistId] = useState(null);
    const [hasError, setHasError] = useState(false);
    const [iframeLoaded, setIframeLoaded] = useState(false);
    const [iframeSrcDoc, setIframeSrcDoc] = useState(''); // State for entire iframe srcDoc
    const [iframeWidth, setIframeWidth] = useState('100%');
    const [totalImagesCount, setTotalImagesCount] = useState(0);
    const [displayedImagesCount, setDisplayedImagesCount] = useState(0);
    const [carouselIndex, setCarouselIndex] = useState(false);
    const [shouldShowScrollButtons, setShouldShowScrollButtons] = useState(false);
    const iframeRef = useRef(null); // Ref for iframe
    const buttonAndImageRef = useRef(null);

    // Fetch the iNaturalistId when taxon_name changes
    useEffect(() => {
        setShouldShowScrollButtons(false);
        axios.get(`https://www.inaturalist.org/taxa/search.json?per_page=10&q=${taxon_name}`)
            .then(response => {
                console.log(response.data);
                if (response.data.length > 0 && response.data[0].id) {
                    setINaturalistId(response.data[0].id);
                    setHasError(false);
                } else {
                    setHasError(true);
                    setShouldShowScrollButtons(false);
                }
            })
            .catch(error => {
                console.error("Error fetching iNaturalist data:", error);
                setHasError(true);
            });
    }, [taxon_name]);

    // Generate the iframe srcDoc when iNaturalistId changes.
    // This is a very complicated iframe manipulation block which is used to avoid having to 
    // make repeated iNaturalist API queries when interacting with the widget on the page.
    useEffect(() => {
        if (iNaturalistId) {
            const srcDoc = `
            <html>
            <head>
                <style>
                body {
                    display: flex;
                    justify-content: center;
                    margin: 0;
                    overflow: hidden;
                }
                .image-container {
                    width: 100%;
                    display: flex;
                    transition: transform 0.5s ease;
                }
                img.crop-to-square {
                    object-fit: cover;
                    border-radius: 6px;
                }
                </style>
                <script type="text/javascript" src="https://www.inaturalist.org/observations.widget?layout=small&limit=10&order=desc&order_by=votes&quality_grade=research&taxon_id=${iNaturalistId}" charset="utf-8"></script>
                <script>
                let currentPosition = -${cardHeight + 6};
                let imageWidth;
                let carouselIndex = 0;
                let scrolling = false;
                const ANIMATION_DURATION = 500;

                window.onload = function() {
                    var links = document.getElementsByTagName('a');
                    var images = document.getElementsByTagName('img');
                    
                    // Replace images with "medium.jpg" versions
                    for (var i = 0; i < images.length; i++) {
                        images[i].src = images[i].src.replace('square', 'medium');
                        images[i].classList.add('crop-to-square');
                        links[i].target = '_blank';
                    }

                    // Create new ImageContainer, then fill it with <a> elements containing the images.
                    // Note that this starts by appending the *last* element, since we start shifted and hiding the first element.
                    var imageContainer = document.createElement('div');
                    imageContainer.className = 'image-container';
                    if (links.length > 0) {
                        imageContainer.appendChild(links[links.length - 1]); // Append the last link (index -1)
                    }
                    while (links.length > 0) {
                        imageContainer.appendChild(links[0]);
                    }
                    document.body.appendChild(imageContainer);

                    // Remove inat-widget-small, which causes a spacing issue
                    document.querySelector('.inat-widget-small').remove();

                    window.parent.postMessage({type: 'iframeLoaded', totalImagesCount: images.length});
                };

                // Listen for messages from parent to update image size or shift images
                window.addEventListener('message', (event) => {
                    var links = Array.from(document.querySelectorAll('.inat-observation-image'));
                    var images = Array.from(document.querySelectorAll('.crop-to-square'));
                    if (event.data.type === 'updateHeight') {
                        const imageContainer = document.querySelector('.image-container');
                        transformDistance = event.data.scrollEnabled ? event.data.height + 6 : 0
                        const newTransX = 'translateX(-' + transformDistance + 'px)';
                        imageContainer.style.transition = 'none'; // Disable animation to prevent sliding on resize
                        imageContainer.style.transform = newTransX;
                        imageContainer.style.justifyContent = event.data.scrollEnabled ? 'start' : 'center';

                        links.forEach(link => {
                            link.style.width = event.data.height + 'px';
                            link.style.height = event.data.height + 'px';
                            link.style.margin = '0 3px';
                        });

                        images.forEach(image => {
                            image.style.width = event.data.height + 'px';
                            image.style.height = event.data.height + 'px';
                        });

                        // Reenable animation shortly after resize
                        setTimeout(() => {
                            imageContainer.style.transition = 'transform 0.5s ease';    
                        }, 200)
                    } else if (event.data.type === 'shiftIndexRight') {
                        shiftImages(-1, event.data.height);
                    } else if (event.data.type === 'shiftIndexLeft') {
                        shiftImages(1, event.data.height);
                    }
                });

                function shiftImages(direction, cardHeight) {
                    if (!scrolling) {
                        scrolling = true;
                        const heightWithMargin = cardHeight + 6;
                        const imageContainer = document.querySelector('.image-container');
                        const imageLinks = Array.from(imageContainer.querySelectorAll('a'));
                        
                        currentPosition = -direction * heightWithMargin - heightWithMargin;
                        translateClause = 'translateX(' + currentPosition + 'px)';
                        imageContainer.style.transform = translateClause;

                        // Change carousel index & communicate to parent
                        carouselIndex += direction;
                        window.parent.postMessage({type: 'carouselIndexChanged', carouselIndex: carouselIndex})
                        
                        setTimeout(() => {
                            if (direction > 0) {
                                imageContainer.appendChild(imageLinks[0]);
                            } else {
                                imageContainer.insertBefore(imageLinks[imageLinks.length - 1], imageLinks[0]);
                            }
                            
                            currentPosition = -heightWithMargin;
                            imageContainer.style.transition = 'none';
                            imageContainer.style.transform = 'translateX(' + currentPosition + 'px)';

                            // Re-enable transition and scrolling after a brief delay
                            setTimeout(() => {
                                imageContainer.style.transition = 'transform 0.5s ease';
                                scrolling = false;
                            }, 50);
                        }, ANIMATION_DURATION);
                    }
                }
                </script>
            </head>
            
            <body>
                <!-- Content here will be displayed in the iframe -->
            </body>
            </html>
            `;
            setIframeSrcDoc(srcDoc);
            setCarouselIndex(0);
        }
    }, [iNaturalistId]);

    // Reset isLoading if taxon name changes
    useEffect(() => {
        setIframeLoaded(false);
    }, [taxon_name]);

    // Disable loading once iNat widget has been re-rendered with html edits
    useEffect(() => {
        const handleMessage = (event) => {
            if (event.data.type === 'iframeLoaded') {
                setIframeLoaded(true);
                setTotalImagesCount(event.data.totalImagesCount);
            } else if (event.data.type === 'carouselIndexChanged') {
                setCarouselIndex(event.data.carouselIndex);
                //console.log(event.data.carouselIndex);
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    const widgetHeight = cardHeight - 20;

    // Send a message to the iframe to update the image sizes when cardHeight changes
    useEffect(() => {
        if (iframeLoaded && iframeRef.current) {
            iframeRef.current.contentWindow.postMessage({
                type: 'updateHeight',
                height: widgetHeight,
                scrollEnabled: shouldShowScrollButtons
            }, '*');
        }
    }, [widgetHeight, iframeLoaded, shouldShowScrollButtons]);

    const shiftIndexRight = () => {
        if (iframeRef.current) {
            iframeRef.current.contentWindow.postMessage({
                type: 'shiftIndexRight',
                height: widgetHeight
            }, '*')
        }
    }

    const shiftIndexLeft = () => {
        if (iframeRef.current) {
            iframeRef.current.contentWindow.postMessage({
                type: 'shiftIndexLeft',
                height: widgetHeight
            }, '*')
        }
    }

    // Calculate the iframe width based on cardHeight and parent width
    // Also check whether scroll buttons are needed based on number of images + calculated width
    useEffect(() => {
        const updateIframeWidth = () => {
            if (buttonAndImageRef.current) {
                const numDisplayed = Math.floor((buttonAndImageRef.current.clientWidth - 130) / (cardHeight - 11));
                const iframeCalculatedWidth =  numDisplayed * (cardHeight - 15);
                console.log(buttonAndImageRef.current.clientWidth);
                console.log(iframeCalculatedWidth);
                setDisplayedImagesCount(numDisplayed);
                setIframeWidth(iframeCalculatedWidth);
                setShouldShowScrollButtons(numDisplayed < totalImagesCount);
                setHasError(!totalImagesCount && iframeLoaded)
            };
        };

        // Initial calculation
        updateIframeWidth();

        // Update on window resize
        window.addEventListener('resize', updateIframeWidth);

        return () => {
            window.removeEventListener('resize', updateIframeWidth);
        };
    }, [cardHeight, totalImagesCount, iframeLoaded]);

    if (hasError) {
        return (
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: '100%'}}>
                <p style={{ marginLeft: '10px', marginTop: '10px' }}>No images available for taxon.</p>
            </div>
        );
    }

    // From: https://stackoverflow.com/questions/4467539/javascript-modulo-gives-a-negative-result-for-negative-numbers
    const mod = (n, m) => (n % m + m) % m;

    return (
        <div className="inaturalist-widget-component" style={{ height: '100%' }}>
            {!iframeLoaded && (
                    <Loading />
            )}
            <div className="inaturalist-widget-body" style={{ display: "flex", flexDirection: "column", height: '100%', visibility: iframeLoaded ? 'visible' : 'hidden' }}>
                <div ref={buttonAndImageRef} style={{ justifyContent: 'center', overflow: 'hidden', display: 'flex'}}>
                    {shouldShowScrollButtons && (
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center", flex: 1}}>
                            <button className="btn" onClick={shiftIndexRight} style={{
                                padding: "12px", height: widgetHeight/2, width: "40px", marginRight: "15px", marginLeft: "auto",
                                borderRadius: "16px 0px 0px 16px"
                            }}>
                                <i className="nc-icon nc-minimal-left" style={{marginRight: "1px"}}></i>
                            </button>
                        </div>
                    )}
                    <iframe
                        ref={iframeRef}
                        id="iNatiframe"
                        style={{
                            width: iframeWidth + "px",
                            border: 'none',
                            height: (cardHeight).toString() + 'px'
                        }}
                        srcDoc={iframeSrcDoc}
                        title="iNaturalist Widget"
                    />
                    {shouldShowScrollButtons && (
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center", flex: 1}}>
                            <button className="btn" onClick={shiftIndexLeft} style={{
                                padding: "12px", height: widgetHeight/2, width: "40px", marginRight: "auto", marginLeft: "15px",
                                borderRadius: "0px 16px 16px 0px"
                            }}> 
                                <i className="nc-icon nc-minimal-right" style={{marginLeft: '1px'}}></i>
                            </button>
                        </div>
                    )}
                </div>
                <div style={{alignSelf: 'center', padding: '0px 0', fontSize: '20px', marginTop: '3px'}}>
                    {totalImagesCount > displayedImagesCount ? (
                        "○ ".repeat(mod(carouselIndex, totalImagesCount)) +
                        "● " +
                        "○ ".repeat(mod((totalImagesCount - carouselIndex - 1), totalImagesCount))
                    ) : ("●")}
                </div>
            </div>
        </div>
    );
};

export default INaturalistWidget;
