import Spinner from "../../assets/img/taxo-spin.gif";

const Loading = () => {
    return (
        <div className="loading">
            Loading...
            <img src={Spinner} alt="Loading..." width="32px" height="32px"></img>
        </div>
    )
}

export default Loading;