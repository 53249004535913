import React, { useState, useEffect, useRef } from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Routes, Navigate, useLocation } from "react-router-dom"; // Import Navigate here

import DemoNavbar from "../components/Navbars/Navbar.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import { checkAuthStatus } from "../util/isAuth";

import routes from "../routes.js";
import NotFound from "../pages/NotFoundPage.js";

var ps;

function Dashboard(props) {
  const [backgroundColor] = useState("black");
  const [activeColor] = useState("warning");
  const mainPanel = useRef();
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.body.classList.toggle("perfect-scrollbar-on");
      }
    };
  });
  
  useEffect(() => {
    mainPanel.current.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [location]);

  useEffect(() => {
    const verifyAuthentication = async () => {
      const authStatus = await checkAuthStatus();
      setIsAuthenticated(authStatus);
      setLoading(false);
    };

    verifyAuthentication();
  }, []);

  return (
    <div className="wrapper">
      <Sidebar
        {...props}
        routes={routes}
        bgColor={backgroundColor}
        activeColor={activeColor}
        isAuthenticated={isAuthenticated}
      />
      <div className="main-panel" ref={mainPanel}>
        <DemoNavbar {...props} routes={routes} isAuthenticated={isAuthenticated}/>
        <Routes key={location.pathname}>
          {routes.map((prop, key) => {
            if (!loading) {
              if (prop.authRequired && !isAuthenticated) {
                // return_page component currently bugged, unused
                const currentPath = location.pathname.slice(1)
                const loginPath = `/login-page?return_page=${encodeURIComponent(currentPath)}`;
                return <Route path={prop.path} element={<Navigate to={loginPath} />} key={key} />;
              } else {
                return <Route path={prop.path} element={prop.component} key={key} />;
              }
            } else {
              return <Route path={prop.path} element={""} key={key}></Route>
            }
          })}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </div>
  );
}

export default Dashboard;
