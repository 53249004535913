import React, { Component } from 'react';
import axios from 'axios';
import { Link, Navigate } from 'react-router-dom';
import ShowTaxonCharacters from '../Helpers/ShowTaxonCharacters';
import DropdownSearch from '../Helpers/DropdownSearch';
import Loading from '../Helpers/Loading';
import { Button, CardBody, CardHeader, CardTitle } from 'reactstrap';

class TaxonDescription extends Component {
  state = {
      taxon: null,
      oldTaxon: null,
      isLoading: true,
      error: null,
      isEditMode: false,
      forceUpdateTrigger: false,
      shouldRedirect: false,
  };

  handleCharacterClick = (selectedCharacter) => {
    if (!this.props.isAuthenticated) return;

    selectedCharacter.value = ""

    console.log(this.state.taxon);
    console.log('debug 1');

    if (!this.state.taxon.characters || !this.state.taxon.characters[selectedCharacter.character_name]) {
      console.log('debug 2');
      this.setState(prevState => ({
        taxon: {
          ...prevState.taxon,
          characters: {
            ...prevState.taxon.characters, 
            [selectedCharacter.character_name]: selectedCharacter
          }
        }
      }));
    };
  };

  toggleEditMode = () => {
    if (!this.props.isAuthenticated) return;

    if (this.state.isEditMode) {
      this.setState({taxon: this.state.oldTaxon})
    } else {
      this.setState({oldTaxon: this.state.taxon})
    }

    this.setState(prevState => ({ isEditMode: !prevState.isEditMode }));
  };

  handleCharacterChange = (newValue, characterToChange) => {
    if (!this.props.isAuthenticated) return;

    // Update taxon characters
    this.setState(prevState => ({
      taxon: {
        ...prevState.taxon,
        characters: {
          ...prevState.taxon.characters, 
          [characterToChange]: {
            ...prevState.taxon.characters[characterToChange],
            value: newValue
          }
        }
      }
    }));
  };

  handleCharacterDelete = (character) => {
    if (!this.props.isAuthenticated) return;

    // Create a copy of taxon without the deleted character
    const { [character]: deletedCharacter, ...remainingCharacters } = this.state.taxon.character;

    console.log(remainingCharacters)
    console.log(this.state.taxon)

    // Update the state with the modified taxon
    this.setState(prevState => ({
      taxon: {
        ...prevState.taxon,
        characters: remainingCharacters
      }
    }));
  };

  saveChanges = async () => {
    if (!this.props.isAuthenticated) return;

    // Implement logic to save changes to the backend
    await axios.post('/api/desc', this.state.taxon)
        .then(response => {
            // Handle successful save
            console.log('Changes saved:', response.data);
            this.setState({ isEditMode: false });
        })
        .catch(error => {
            // Handle save error
            console.error("Error saving changes:", error);
        });

    this.getDescription(this.props.taxon_name);
  };

  componentDidMount() {
    const { taxon_name } = this.props;
    this.getDescription(taxon_name);

    this.adjustIframeHeight();
    window.addEventListener('resize', this.adjustIframeHeight);
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if taxon_name prop has changed
    if (this.props.taxon_name !== prevProps.taxon_name) {
      this.getDescription(this.props.taxon_name);
      this.setState({isLoading: true, isEditMode: false})
    }

    if (this.props.taxon_name !== prevProps.taxon_name || this.state.inaturalistId !== prevState.inaturalistId) {
      this.adjustIframeHeight();
    }
  }

  getDescription = (name) => {
    axios.get(`/api/taxa?taxon_name=${name}&exact=true`)
      .then((res) => this.setState({ taxon: res.data[0], isLoading: false }))
      .catch((error) => this.setState({ error, isLoading: false }));
  };

  forceUpdateComponent = () => {
    this.setState(prevState => ({
      forceUpdateTrigger: !prevState.forceUpdateTrigger, // Toggle the state to force re-render
    }));
  };
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.adjustIframeHeight);
  }

  adjustIframeHeight = () => {
    const iframe = document.getElementById('iNatiframe'); // Make sure your iframe has this ID
    if (iframe) {
      const totalImages = 30; // Total number of images to display
      const imageWidthWithPadding = 154; 
      const iframeWidth = iframe.offsetWidth; // Dynamically get the current width of the iframe
  
      const imagesPerRow = Math.floor(iframeWidth / imageWidthWithPadding);
      const numberOfRows = Math.ceil(totalImages / imagesPerRow);
  
      // Assuming the height of one image with padding is the same as its width
      const totalHeightNeeded = numberOfRows * imageWidthWithPadding;
  
      iframe.style.height = `${totalHeightNeeded}px`;
    };
  };

  redirectToKeysPage = () => {
    this.setState({ shouldRedirect: true });
  };

  render() {
    const { taxon, isLoading, error, isEditMode, shouldRedirect } = this.state;
    const { isAuthenticated } = this.props;

    if (error) {
      return <div>Error: {error.message}</div>;
    }

    return (
      <>
        {isLoading && (
            <Loading style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 1 }} />
        )}
        {taxon && (
          <div className='taxon-description-container' style={{opacity: isLoading ? 0.25 : 1}}>
            <CardHeader className='taxon-description-header'>
              <i className="nc-icon nc-atom" style={{fontSize: '45px', marginTop: '16px'}}/>
              <div style={{display: 'flex', flexDirection: 'column', maxWidth: '50%', hyphens: 'auto'}}>
                <CardTitle tag="h3" className="impactful" style={{marginBottom: '0px', alignItems: 'center'}}>
                  <div style={{marginLeft: '5px'}}>{taxon.taxon_name.charAt(0).toUpperCase() + taxon.taxon_name.slice(1)}</div>
                  {/*<span style={{ fontSize: '0.5em', marginLeft: '6px', color:'gray', verticalAlign: 'middle' }}>(taxon id: {taxon.taxon_id})</span>*/}
                </CardTitle>
                {taxon.parent_name && (
                  <h6 style={{marginLeft: '5px'}}>
                    <Link to={`/search?initial_taxon_name=${taxon.parent_name}`} onClick={this.forceUpdateComponent}>&#60; {taxon.parent_name}</Link>
                  </h6>
                )}
              </div>
              <div className="taxon-description-button-box">
                {isAuthenticated && (
                  <>
                    <Button
                      className="taxon-description-header-button" onClick={this.toggleEditMode} color={isEditMode ? 'danger' : 'primary'}>
                      {isEditMode ? "Cancel" : "Edit"}
                    </Button>
                    {isEditMode && (
                      <>
                        <Button className="taxon-description-header-button" onClick={this.saveChanges} color='success'>
                          Save
                        </Button>
                      </>
                    )}
                  </>
                )}
                {taxon.subtaxon_ids && (
                  <Button onClick={this.redirectToKeysPage} className="taxon-description-header-button" color='warning'>Generate Key</Button>
                )}
              </div>
            </CardHeader>
            <CardBody style={{paddingTop: '5px', display: 'flex', flexDirection: 'column'}}>
              {isEditMode && (
                <>
                  <p>Add Taxon Character:</p>
                  <DropdownSearch
                    onDocumentClick={this.handleCharacterClick}
                    collectionName={"characters"}
                    itemLabelKey={"character_name"}
                    id="character_name"
                  />
                </>
              )}
              <div className="taxon-characters">
                <ShowTaxonCharacters
                  taxon={taxon} 
                  isLoading={isLoading}
                  isEditMode={isEditMode} 
                  onCharacterChange={this.handleCharacterChange}
                  onCharacterDelete={this.handleCharacterDelete}
                />
              </div>
            </CardBody> 
          </div>
        )}
        {shouldRedirect && (
          <Navigate to={`/generate-key?initial_taxon_id=${this.state.taxon.taxon_id}&initial_taxon_name=${this.state.taxon.taxon_name}`} />
        )}
      </>
    );
  }
}

export default TaxonDescription;
