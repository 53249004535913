import Dagre from '@dagrejs/dagre';
import { ReactFlow, Handle, MarkerType, ReactFlowProvider, useReactFlow, BaseEdge, EdgeLabelRenderer, getBezierPath } from '@xyflow/react';
import { NLeucopus, PCitrinum, PLeucophaeum, Physarum } from "../../assets/img/reactflow";
import '@xyflow/react/dist/style.css';
import { useMemo, useEffect, useRef, useState, useLayoutEffect } from 'react';
import FlowBackground from './FlowBackground.js';

const DemoFlowchart = () => {
  return (
    <ReactFlowProvider>
      <FlowchartContent />
    </ReactFlowProvider>
  );
};

const FlowchartContent = () => {
    const reactFlowInstance = useRef(null);
    const { fitView } = useReactFlow(); 
    const [direction, setDirection] = useState('LR');  // Initial direction
    const [isSmallWindow, setIsSmallWindow] = useState(window.innerWidth < 768);

    useEffect(() => {
      const updateLayoutDirection = () => {
          if (reactFlowInstance.current) {
              const flowchart = reactFlowInstance.current;
              const flowchartWidth = flowchart.clientWidth;
              const flowchartHeight = flowchart.clientHeight;

              // Check if the width is at least 0.9 times the height -- helps maximize space occupied by flowchart
              if (flowchartWidth >= 0.9 * flowchartHeight) {
                  setDirection('LR');
              } else {
                  setDirection('TB');
              }

              // Determine if mobile view should be applied -- 
              const screenWidth = window.innerWidth;
              if (screenWidth < 1600) {
                  setIsSmallWindow(true);
              } else {
                setIsSmallWindow(false);
              }
          }
      };

      // Initial check
      updateLayoutDirection();
    }, []);

    const ImageNode = ({ data }) => {
        return (
            <div className='card' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '120px',
                textAlign: 'center', padding: '10px', borderRadius: 5,
                backgroundColor: 'white' }}>
                
                <img src={data.image} alt="Taxon Node" style={{ width: 100, height: 100 }} />
                <b style={{marginTop: '10px'}}>{data.label}</b>

                <Handle style={{opacity: 0}} type="source" position={direction === "LR" ? "right" : "bottom"} />
                <Handle style={{opacity: 0}} type="target" position={direction === "LR" ? "left" : "top"} />
            </div>
        );
      };

    const KeyNode = () => {
      return (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',
              textAlign: 'center', padding: '10px', border: '1px solid #ddd', borderRadius: 31,
              backgroundColor: 'white' }}>
              <i className="nc-icon nc-key-25" style={{ fontSize: 40 }}/>

              <Handle style={{opacity: 0}} type="source" position={direction === "LR" ? "right" : "bottom"} />
              <Handle style={{opacity: 0}} type="target" position={direction === "LR" ? "left" : "top"} />
          </div>
      );
    };

    const KeyEdge = ({
      id,
      sourceX,
      sourceY,
      targetX,
      targetY,
      sourcePosition,
      targetPosition,
      style = {},
      markerEnd,
      label
    }) => {
      const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
      });
    
      return (
        <>
          <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
          <EdgeLabelRenderer>
            <div
              style={{
                position: 'absolute',
                transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                fontSize: 12,
                textAlign: 'left',
              }}
              className="nodrag nopan"
            >
              {/* Background rectangle */}
              <div
                style={{
                  backgroundColor: 'white', // White background
                  maxWidth: '200px',
                  padding: '5px 10px', // Add some padding
                  borderRadius: '5px', // Rounded corners
                  boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)', // Optional shadow for better visibility
                }}
              >
                {label}
              </div>
            </div>
          </EdgeLabelRenderer>
        </>
      );
    };
    
    const nodeTypes = useMemo(() => ({
      imageNode: (props) => <ImageNode {...props} direction={direction} />,
      keyNode: (props) => <KeyNode {...props} direction={direction} />
    }), [direction]);

    const edgeTypes = useMemo(() => ({
      keyEdge: KeyEdge
    }), []);
    
    const markerEndStyle = {type: MarkerType.ArrowClosed, color: '#AAA', width: 15, height: 15}

    const largeNodes = [
        { id: 'Physarum', type: 'imageNode', position: { x: 0, y: 150 }, data: { label: 'Physarum', image: Physarum}, draggable: false, },
        { id: 'Key', type: 'keyNode', position: { x: 400, y: 50 }, data: { label: '', isTaxon: false}, draggable: false, },
        { id: 'Physarum leucopus', type: 'imageNode', position: { x: 800, y: 0 }, data: { label: 'Physarum leucopus', image: NLeucopus}, draggable: false, },
        { id: 'Physarum leucophaeum', type: 'imageNode', position: { x: 800, y: 175 }, data: { label: 'Physarum leucophaeum', image: PLeucophaeum}, draggable: false, },
        { id: 'Physarum citrinum', type: 'imageNode', position: { x: 400, y: 250 }, data: { label: 'Physarum citrinum', image: PCitrinum}, draggable: false, },
    ];
    const smallNodes = [
      { id: 'Physarum', type: 'imageNode', position: { x: 0, y: 150 }, data: { label: 'Physarum', image: Physarum} },
      { id: 'Physarum leucopus', type: 'imageNode', position: { x: 800, y: 0 }, data: { label: 'Physarum leucopus', image: NLeucopus}, draggable: false, },
      { id: 'Physarum citrinum', type: 'imageNode', position: { x: 400, y: 250 }, data: { label: 'Physarum citrinum', image: PCitrinum}, draggable: false, },
    ];
    const largeEdges = [
        { id: 'P-K', type: 'keyEdge', source: 'Physarum', target: 'Key', label: 'Mineral deposits white', markerEnd: markerEndStyle, animated: true, selectable: false, },
        { id: 'K-Ps', type: 'keyEdge', source: 'Key', target: 'Physarum leucopus', label: 'Outer layer iridescent blue', markerEnd: markerEndStyle, animated: true, selectable: false, },
        { id: 'K-Pm', type: 'keyEdge', source: 'Key', target: 'Physarum leucophaeum', label: 'Outer layer dull, dark', markerEnd: markerEndStyle, animated: true, selectable: false, },
        { id: 'P-Pc', type: 'keyEdge', source: 'Physarum', target: 'Physarum citrinum', label: 'Mineral deposits yellow', markerEnd: markerEndStyle, animated: true, selectable: false, },
    ];
    const smallEdges = [
        { id: 'P-Pl', type: 'keyEdge', source: 'Physarum', target: 'Physarum leucopus', label: 'Sporocarps blue', markerEnd: markerEndStyle, animated: true },
        { id: 'P-Pc', type: 'keyEdge', source: 'Physarum', target: 'Physarum citrinum', label: 'Sporocarps yellow', markerEnd: markerEndStyle, animated: true },
    ];

    const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
    g.setDefaultEdgeLabel(() => ({}));

    const getLayoutedElements = (nodes, edges, options) => {
      g.setGraph({ 
        rankdir: options.direction,
        ranksep: 200,
        nodesep: options.direction === "TB" ? 150 : 50,
      });
    
      // Set default dimensions if measured dimensions are not available
      const defaultDimensions = {
          imageNode: { width: 120, height: 150 }, // ~Size of average ImageNode
          keyNode: { width: 62, height: 62 },   // Size of KeyNode
      };
  
      edges.forEach((edge) => g.setEdge(edge.source, edge.target));
      nodes.forEach((node) =>
        g.setNode(node.id, {
          ...node,
          width: node.measured?.width ?? defaultDimensions[node.type]?.width ?? 100, // Fallback to 100px if type not matched
          height: node.measured?.height ?? defaultDimensions[node.type]?.height ?? 100,
        }),
      );
    
      Dagre.layout(g);
  
      return {
        nodes: nodes.map((node) => {
          const position = g.node(node.id);
          const nodeWidth = node.measured?.width ?? defaultDimensions[node.type]?.width ?? 100;
  
          // Center the node horizontally by adjusting the x position based on its width; also add offset
          const x = position.x - nodeWidth / 2 + 40;
          const y = position.y - (node.measured?.height ?? defaultDimensions[node.type]?.height ?? 100) / 2 + 25;
  
          return { ...node, position: { x, y } };
        }),
        edges,
      };
    };

    // Select the appropriate nodes and edges based on screen size
    const selectedNodes = isSmallWindow ? smallNodes : largeNodes;
    const selectedEdges = isSmallWindow ? smallEdges : largeEdges;

    const { nodes: layoutedNodes, edges: layoutedEdges } = useMemo(() => {
      return getLayoutedElements(selectedNodes, selectedEdges, { direction });
    }, [direction, largeNodes, largeEdges]);
    
    useLayoutEffect(() => {
        // Use a setTimeout to ensure everything is rendered before calling fitView
        setTimeout(() => {
          fitView({ padding: isSmallWindow ? 0.2 : 0.05 });
        }, 50);
    }, [layoutedNodes, layoutedEdges, direction, fitView, isSmallWindow]);
    

    return (
        <ReactFlow
            ref={reactFlowInstance}
            style={{ border: '2px gainsboro dotted', borderRadius: '12px', height: '100%', width: '100%' }}
            nodes={layoutedNodes}
            edges={layoutedEdges}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
        >
            <FlowBackground backgroundColor='#fdfdfd' />
        </ReactFlow>
    );
};

export default DemoFlowchart;
