/*!

=========================================================
* Paper Dashboard React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import HomePage from "./pages/HomePage.js";
import SearchPage from "./pages/SearchTaxaPage.js";
import KeysPage from "./pages/GenerateKeyPage.js";
import AddTaxonPage from "./pages/AddTaxonPage.js";
import CharacterGlossaryPage from "./pages/CharacterGlossaryPage.js";
import LoginPage from "./pages/LoginPage.js";
import LogoutPage from "./pages/LogoutPage.js";
import AboutPage from "./pages/AboutPage.js";

const routes = [
  {
    path: "/home",
    name: "Home",
    icon: "nc-icon nc-atom",
    component: <HomePage />,
    layout: "",
  },
  {
    path: "/search",
    name: "Search Taxa",
    icon: "nc-icon nc-zoom-split",
    component: <SearchPage />,
    layout: "",
  },
  {
    path: "/add-taxon",
    name: "Add Taxon",
    icon: "nc-icon nc-simple-add",
    component: <AddTaxonPage />,
    layout: "",
    authRequired: true
  },
  {
    path: "/character-lexicon",
    name: "Character Glossary",
    icon: "nc-icon nc-book-bookmark",
    component: <CharacterGlossaryPage />,
    layout: "",
    authRequired: true
  },
  {
    path: "/generate-key",
    name: "Generate Key",
    icon: "nc-icon nc-key-25",
    component: <KeysPage />,
    layout: "",
  },
  {
    path: "/about",
    name: "About",
    icon: "nc-icon nc-alert-circle-i",
    component: <AboutPage />,
    layout: "",
    pro: true,
  },
  {
    path: "/login",
    name: "Login",
    icon: "nc-icon nc-single-02",
    component: <LoginPage />,
    layout: "",
  },
  {
    path: "/logout",
    name: "Logout",
    icon: "nc-icon nc-single-02",
    component: <LogoutPage />,
    layout: "",
  }
];

export default routes;
