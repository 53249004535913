import Dagre from '@dagrejs/dagre';
import { ReactFlow, Handle, MarkerType, BaseEdge, EdgeLabelRenderer, getBezierPath, ReactFlowProvider } from '@xyflow/react';
import TDaddy from "../../assets/img/ultra-secret-admin-info.png";
import '@xyflow/react/dist/style.css';
import { useEffect, useMemo, useRef, useState } from 'react';
import FlowBackground from './FlowBackground';
import { Card } from 'reactstrap';

const KeyFlowchart = ({ keyJson }) => {
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const reactFlowInstance = useRef(null);

  const onInit = (reactFlow) => {
    reactFlowInstance.current = reactFlow;
  };

  function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const ImageNode = ({ data }) => {
    return (
      <div style={{height: '174px', display: 'flex', alignItems: 'center'}}>
        <Card style={{ maxWidth: '120px', textAlign: 'center', padding: '10px',}}>
          {/*<img src={data.image} alt="Taxon Node" style={{ width: 100, height: 100 }} />*/}
          <i className="nc-icon nc-image" style={{ fontSize: 80, color: getRandomColor() }}/>
          <b style={{ marginTop: '10px', flexGrow: '1' }}>{data.label}</b>

          <Handle style={{ opacity: 0 }} type="source" position={data.sourcePosition ?? 'right'} />
          <Handle style={{ opacity: 0 }} type="target" position={data.targetPosition ?? 'left'} />
        </Card>
      </div>
    );
  };

  const KeyNode = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',
            textAlign: 'center', padding: '10px', border: '1px solid #ddd', borderRadius: 31, 
            backgroundColor: 'white' }}>
            <i className="nc-icon nc-key-25" style={{ fontSize: 40 }}/>

            <Handle style={{opacity: 0}} type="source" position="right" />
            <Handle style={{opacity: 0}} type="target" position="left" />
        </div>
    );
  };

  const KeyEdge = ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd,
    data
  }) => {
    const [edgePath, labelX, labelY] = getBezierPath({
      sourceX,
      sourceY,
      sourcePosition,
      targetX,
      targetY,
      targetPosition,
    });

    const formattedEntries = Object.entries(data.characters ?? {}).map(([label, description], index) => (
      <div key={index} style={{}}>
        <div><b>{label}:</b> {description}</div>
      </div>
    ));
  
    return (
      <>
        <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
        <EdgeLabelRenderer>
          <div
            style={{
              position: 'absolute',
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              fontSize: 12,
              textAlign: 'left',
            }}
            className="nodrag nopan"
          >
            {/* Background rectangle */}
            <div
              style={{
                backgroundColor: 'white', // White background
                maxWidth: '200px',
                padding: '5px 10px', // Add some padding
                borderRadius: '5px', // Rounded corners
                boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)', // Optional shadow for better visibility
              }}
            >
              {formattedEntries}
            </div>
          </div>
        </EdgeLabelRenderer>
      </>
    );
  };

  const nodeTypes = useMemo(
    () => ({
      TTNode: ImageNode,
      KNode: KeyNode,
    }),
    []
  );

  const edgeTypes = useMemo(
    () => ({
      KEdge: KeyEdge
    }),
    []
  )

  const createIdMap = (keyArray) => {
    const idMap = {};
    keyArray.forEach(entry => {
      idMap[entry.id] = entry;
    });
    return idMap;
  };

  function addParentIdToNodes(nodeMap) {
    Object.keys(nodeMap).forEach(nodeId => {
      const node = nodeMap[nodeId];
      if (node.children && node.children.length > 0) {
        node.children.forEach(childId => {
          if (nodeMap[childId]) {
            nodeMap[childId].parentId = nodeId;
          }
        });
      }
    });
    return nodeMap;
  }

  useEffect(() => {
    const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
    g.setDefaultEdgeLabel(() => ({}));

    const getLayoutedElements = (nodes, edges, options) => {
      g.setGraph({
        rankdir: options.direction,
        nodesep: '62',
        ranksep: '300',
        ranker: 'tight-tree'
      });

      if (!nodes || !edges) return { nodes: [], edges: [] };

      const defaultDimensions = {
        TTNode: { width: 120, height: 174 },
        KNode: { width: 62, height: 150 },
      };

      edges.forEach((edge) => g.setEdge(edge.source, edge.target));
      nodes.forEach((node) =>
        g.setNode(node.id, {
          ...node,
          width: node.measured?.width ?? defaultDimensions[node.type]?.width ?? 100,
          height: node.measured?.height ?? defaultDimensions[node.type]?.height ?? 100,
        })
      );

      Dagre.layout(g);

      const leftmostNode = nodes.reduce((minNode, node) => {
        const position = g.node(node.id);
        return position.x < g.node(minNode.id).x ? node : minNode;
      }, nodes[0]);

      const leftmostY = g.node(leftmostNode.id).y;

      const viewportHeight = window.innerHeight;
      const verticalCenter = viewportHeight / 3 - 20;

      const yOffset = verticalCenter - leftmostY;

      return {
        nodes: nodes.map((node) => {
          const position = g.node(node.id);
          const nodeWidth = node.measured?.width ?? defaultDimensions[node.type]?.width ?? 100;
          // TODO: also correct x upon rerender
          const x = position.x - nodeWidth / 2 + 40;
          const y = position.y + yOffset;

          return { ...node, position: { x, y } };
        }),
        edges,
      };
    };

    if (keyJson) {
      const nodes = [];
      const edges = [];

      const markerEndStyle = { type: MarkerType.ArrowClosed, color: '#AAA', width: 15, height: 15 };

      // Necessary due to JSON being returned in string format
      console.log("ooga")
      const parsedKeyJson = keyJson.map(entry => JSON.parse(entry));
      const idMap = createIdMap(parsedKeyJson);
      const parentedIdMap = addParentIdToNodes(idMap);

      for (let nodeId in parentedIdMap) {
        const node = parentedIdMap[nodeId];

        if (node.type === 'TTNode') {
          nodes.push({
            id: node.id,
            type: 'TTNode',
            data: { label: node.name, image: TDaddy },
            draggable: false,
          });
          if (node.children && node.children.every(childId => childId.startsWith('Taxon'))) {
            for (let childId of node.children) {
                edges.push({
                    id: node.id + '' + childId,
                    source: node.id,
                    target: childId,
                    markerEnd: markerEndStyle,
                    animated: true,
                    selectable: false,
                });
            }
          }
        } else if (node.type === 'KNode') {
          if (node.children.every(childId => !childId.startsWith('Taxon'))) {
            nodes.push({
              id: node.id,
              type: 'KNode',
              draggable: false,
            });
            edges.push({
              id: node.parentId + '' + node.id,
              source: node.parentId,
              target: node.id,
              type: 'KEdge',
              data: { characters: node.characters },
              markerEnd: markerEndStyle,
              animated: true,
              selectable: false,
            });
          } else {
            for (let childId of node.children) {
              edges.push({
                id: node.parentId + '' + childId,
                source: node.parentId,
                target: childId,
                type: 'KEdge',
                data: { characters: node.characters },
                markerEnd: markerEndStyle,
                animated: true,
                selectable: false,
              });
            }
          }
        } else {
          console.error('Unknown node type "' + node.type + '" returned.');
        }
      }

      const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(nodes, edges, { direction: 'LR' });

      setNodes(layoutedNodes);
      setEdges(layoutedEdges);
    }
  }, [keyJson]);

  return (
    nodes.length > 0 && edges.length > 0 && (
      <div style={{flexGrow: 1}}>
        <ReactFlowProvider>
          <ReactFlow
            style={{ border: 0, borderRadius: '12px'}}
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            onInit={onInit} // Attach the onInit handler
            minZoom={0.1}
            maxZoom={1.5}
          >
            <FlowBackground backgroundColor='#fdfdfd'/>
          </ReactFlow>
        </ReactFlowProvider>
      </div>
      
    )
  );
};

export default KeyFlowchart;
