// Logout.js
import { useEffect } from 'react';
import axios from 'axios';

const Logout = () => {
    useEffect(() => {
        const performLogout = async () => {
            try {
                // Call the logout endpoint
                await axios.get('/logout');
                // Clear any authentication state
                // Redirect to login page or home page
                window.location.href = '/home';
            } catch (error) {
                console.error('Logout failed:', error);
                // Handle errors (optional)
            }
        };

        performLogout();
    }, []);

    return (
        <div className="content">
            Logging out...
        </div>
    );
};

export default Logout;
