/*!

=========================================================
* Paper Dashboard React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import Helmet from "react-helmet";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardText,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import ethan from "../assets/img/ethan-about.png";
import EmailContact from "../components/TaxoKeysCards/EmailContact";

const AboutPage = React.memo(function AboutPage() {
  return (
    <>
      <div className="content">
        <Helmet>
          <title>About | TaxoKeys</title>
        </Helmet>
        <Row>
          {/* Left Column for the long card */}
          <Col md="7">
            <Card>
              <CardHeader>
                <CardTitle className="impactful" tag="h3"><center>Our Mission</center></CardTitle>
              </CardHeader>
              <CardBody>
                <CardText style={{textIndent:'30px'}}>
                  TaxoKeys is a community science tool which functions both as a curated database of organisms and a resource for
                  flowchart-style identification guides known as "dichotomous keys". Utilizing combinatorics algorithms, complex relationships
                  between species can be resolved quickly, reliably, and with little error.
                </CardText>
                <CardText style={{textIndent:'30px'}}>
                  TaxoKeys was created with the idea of free, equitable access to learning resources for anyone seeking to learn
                  to identify organisms. Dichotomous keys for many fields are often hard to obtain, and are written in a
                  jargon-heavy, no-nonsense fashion which discourages newcomers to the field and ultimately maims community contribution
                  to science. TaxoKeys seeks to offer a counterpart to this through its welcoming design and open access to features.
                </CardText>
                <CardText style={{marginBottom: '16px'}}><i>
                  Disclaimer: TaxoKeys is currently in early alpha. Generated keys and/or taxon descriptions may contain errors
                  and should be reviewed before acceptance.
                </i></CardText>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle className="impactful" tag="h3"><center>Meet the Founder</center></CardTitle>
              </CardHeader>
              <CardBody>
                <div style={{ float: 'right', marginRight: '20px' }}>
                  <img src={ethan} alt="" style={{ maxWidth: '200px', height: 'auto', marginLeft: '10px' }} />
                </div>
                <p><strong>Ethan Saso</strong></p>
                <p style={{textIndent:'30px'}}>
                Hi there! My name is Ethan Saso, and I'm a recent graduate from UC Berkeley with a degree in Molecular Environmental Biology
                and Computer Science. I previously led the university's Mycological Society and hold an intense passion for fungi and slime molds.
                In my free time, I also dabble in extreme macro photography of these extraordinary creatures, which you can view over
                on <strong><a href='https://www.inaturalist.org/people/ethansaso'>my iNaturalist</a></strong>.
                </p>
                <p>You can also find me on <strong><a href="https://www.linkedin.com/in/ethansaso/">LinkedIn</a></strong>.</p>
              </CardBody>
            </Card>
          </Col>
          {/* Right Column for Contact Us and Donate cards */}
          <Col md="5">
            {/* Donate Card */}
            <Card>
              <CardHeader>
                <CardTitle  className="impactful"  tag="h3"><center>Donate!</center></CardTitle>
                <center><p>While TaxoKeys is and will always be a free service, we pay out of pocket to keep it online.
                  If you would like to support the site's development, please consider donating. Thank you!</p></center>
              </CardHeader>
              <CardBody>
                {/* Embed the Ko-Fi Widget using an iframe */}
                <iframe
                  src="/ko-fi-widget.html"
                  width="100%"
                  height="100%"
                  title="Ko-Fi Widget"
                  frameBorder="0"
                  scrolling="no"
                  style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}
                ></iframe>
              </CardBody>
            </Card> 
            {/* Contact Card */}
            <Card>
              <CardHeader>
                <CardTitle align="center" className="impactful" tag="h3">Contact Us</CardTitle>
              </CardHeader>
              <CardBody>
                <center><strong><p>Have a question? Feature request? Send us a message below:</p></strong></center>
                <EmailContact />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
});


export default AboutPage;
