import React, { useState, useEffect, useRef } from 'react';
import Helmet from 'react-helmet';
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from 'reactstrap';
import { checkAuthStatus } from '../util/isAuth.js';
import { useLocation } from 'react-router-dom';
import TaxonDescription from '../components/TaxoKeysCards/TaxonDescription.js';
import INaturalistWidget from '../components/TaxoKeysCards/iNaturalistWidget.js';
import DBSearch from '../components/TaxoKeysCards/DBSearch.js';

const SearchPage = () => {
  const [selectedTaxonName, setSelectedTaxonName] = useState(null);
  const [initialSearch, setInitialSearch] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const location = useLocation();

  const iNatWidgetRef = useRef(null);
  const [cardHeight, setCardHeight] = useState(150);

  useEffect(() => {
    // Define a function that updates the card height
    const updateCardHeight = () => {
      if (iNatWidgetRef.current) {
        setCardHeight(iNatWidgetRef.current.offsetHeight);
      }
    };
  
    // Call it on mount and update
    updateCardHeight();
  
    // Also call it whenever the window resizes
    window.addEventListener('resize', updateCardHeight);
  
    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateCardHeight);
    };
  }, [selectedTaxonName]);

  useEffect(() => {
    const verifyAuthentication = async () => {
      const authStatus = await checkAuthStatus();
      setIsAuthenticated(authStatus);
    };

    verifyAuthentication();

    const params = new URLSearchParams(location.search);
    const initialTaxonName = params.get('initial_taxon_name');
    const initialSearch = params.get('initial_search');

    if (initialTaxonName) {
      setSelectedTaxonName(initialTaxonName);
    }
    if (initialSearch) {
      setInitialSearch(initialSearch);
    }
  }, [location.search]);
  
  const handleTaxonClick = (taxon) => {
    setSelectedTaxonName(taxon.taxon_name);
  };

  return (
    <div className="content">
      <Helmet>
        <title>Search Taxa | TaxoKeys</title>
      </Helmet>
      <Row>
        <Col xs="12" xl="4">
          <Card className="search-container">
            <CardHeader>
              <CardTitle tag="h2" className="impactful" style={{display: "flex", flexDirection: "row", alignItems: "center", margin: '20px 5px 0px 0px'}}>
                <i className="nc-icon nc-zoom-split" />
                <div style={{marginLeft: '5px'}}>Search Taxa</div>
              </CardTitle>
            </CardHeader>
            <DBSearch handleTaxonClick={handleTaxonClick} initialSearch={initialSearch}/> 
          </Card>
        </Col>
        <Col xs="12" xl="8">
          {selectedTaxonName && (
            <>
              <Card className="description-container" style={{ height: '50vh' }}>
                <TaxonDescription taxon_name={selectedTaxonName} isAuthenticated={isAuthenticated} />
              </Card>
              <div>
                <div className="inat-widget-header" style={{ marginTop: "30px", marginLeft: "3px", marginBottom: '7px' }}>
                  <a href="https://www.inaturalist.org"><img style={{maxHeight: '23px'}} alt="iNaturalist" src="https://static.inaturalist.org/wiki_page_attachments/3357-original.png" /></a>  
                </div>
                <div ref={iNatWidgetRef}>
                  <Card className="inat-widget-card" style={{ height: 'calc(50vh - 203px)' }}>
                    <CardBody style={{padding: '23px 15px 5px 15px'}}>
                      <INaturalistWidget
                        taxon_name={selectedTaxonName}
                        cardHeight={cardHeight - 45}
                      />
                    </CardBody>
                  </Card>
                </div>
              </div>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default SearchPage;
