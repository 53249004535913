import React, { useState } from 'react';
import redXImage from '../../assets/img/red-x.png';
import TagInput from './TagInput';
import { stringifyCookie } from 'next/dist/compiled/@edge-runtime/cookies';

const ShowCharacterDescription = ({ character, isAuthenticated }) => {
  const [expandedGroups, setExpandedGroups] = useState({});

  /*
  const capitalizeFirstLetter = (str) => {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const handleCharacterChange = (newValue, characterToChange) => {
    if (!this.props.isAuthenticated) return;

    // Update character characters
    this.setState(prevState => ({
      character: {
        ...prevState.character,
        characters: {
          ...prevState.character.characters, 
          [characterToChange]: {
            ...prevState.character.characters[characterToChange],
            value: newValue
          }
        }
      }
    }));
  };

  const handleCharacterDelete = (character) => {
    if (!this.props.isAuthenticated) return;

    // Create a copy of character without the deleted character
    const { [character]: deletedCharacter, ...remainingCharacters } = this.state.character.characters;

    console.log(remainingCharacters)
    console.log(this.state.character)

    // Update the state with the modified character
    this.setState(prevState => ({
      character: {
        ...prevState.character,
        characters: remainingCharacters
      }
    }));
  };

  const handleChange = (e, character) => {
    if (Array.isArray(e.target.value)) {
      onCharacterChange(e.target.value, character);
    } else {
      onCharacterChange(e.target.value, character);
    }
  };

  const handleDelete = (characterName) => {
    onCharacterDelete(characterName);
  };
  */

  const suggested_values_as_string = character ? character.suggested_values.join(", ") : "";
  const suggested_values_capitalized = suggested_values_as_string.slice(0, 1).toUpperCase() + suggested_values_as_string.slice(1).toLowerCase();

  return (
    <div className="character-display">
      {character && (
        <div>
          <div className="character-metadata">
            <b>Maximum entries: </b>{character.max_entries !== 0 ? character.max_entries : "Unlimited"}<br />
            <b>Suggested values: </b>{character.suggested_values.length ? suggested_values_capitalized : "None"}
          </div>
          <div className="character-description">
            {character.description ? character.description : "No description supplied."}
          </div>
          <div style={{margin: "250px 30px", textAlign: "center"}}>
            Please note: character lexicon is currently under construction. This layout and display are not final; rather, they
            were designed quickly to provide functionality for the site. Check back soon!
          </div>
        </div>
      )}
    </div>
  );
};

export default ShowCharacterDescription;