import { useEffect, useState } from "react";

const CountUp = ({ target, duration = 1200 }) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        let frame = 0;
        const target_if_exists = isNaN(target) ? 0 : target;
        const frameDuration = 1000 / 60; // 60 fps
        const totalFrames = Math.round(duration / frameDuration);

        const easeOutQuart = t => 1 - Math.pow(1 - t, 4);

        const countUp = () => {
            frame++;
            const progress = easeOutQuart(frame / totalFrames);
            const currentCount = Math.round(target_if_exists * progress);

            setCount(currentCount);

            if (frame < totalFrames) {
                requestAnimationFrame(countUp);
            } else {
                setCount(target_if_exists); // ensure it ends at the target
            }
        };

        requestAnimationFrame(countUp);
    }, [target, duration]);

    return <span>{count}</span>;
};

export default CountUp;