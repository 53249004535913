// TagInput.js
import React, { useState } from 'react';

const TagInput = ({ suggestedValues, selectedValues, onTagsChange }) => {
  const [input, setInput] = useState('');

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // This will prevent the form from being submitted if it's inside a form
      //console.log('Enter key pressed', input); // Debugging
      if (input && !selectedValues.includes(input)) {
        //console.log('Input is valid and not included in selectedValues'); // Debugging
        if (suggestedValues.includes(input) || true) { // I've added `|| true` to allow any new values, remove it if you only want suggestedValues
          //console.log('Input is in suggestedValues or any value is allowed'); // Debugging
          onTagsChange([...selectedValues, input]);
        }
      }
      setInput('');
    } else if (e.key === 'Backspace' && !input) {
      removeTag(selectedValues[selectedValues.length - 1]);
    }
  };

  const handleInputBlur = () => {
    if (input && !selectedValues.includes(input)) {
      if (suggestedValues.includes(input) || true) {
        onTagsChange([...selectedValues, input]);
      }
    }
    setInput('');
  };

  const removeTag = (tag) => {
    onTagsChange(selectedValues.filter((t) => t !== tag));
  };

  return (
    <div className="tags-input-container">
      {selectedValues.map((tag, index) => (
        <div key={index} className="tag-item">
          <span>{tag}</span>
          <button type="button" onClick={() => removeTag(tag)}>×</button>
        </div>
      ))}
      <input
        type="text"
        value={input}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        onBlur={handleInputBlur}
        placeholder="Type and press enter"
        className="tag-input"
      />
    </div>
  );
};

export default TagInput;
