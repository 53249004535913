import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Nav } from "reactstrap";
import PerfectScrollbar from "perfect-scrollbar";
import logo from "../../assets/img/taxo-square.png";

function Sidebar(props) {
  const [adjustedRoutes, setAdjustedRoutes] = useState([]);
  const location = useLocation();
  const sidebarRef = useRef(null);

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      const ps = new PerfectScrollbar(sidebarRef.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });

      return () => ps.destroy();
    }
  }, [location.pathname]);

  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  useLayoutEffect(() => {
    const filteredRoutes = props.routes.filter(route => 
      route.path !== "/login" && route.path !== "/logout"
    );
    setAdjustedRoutes(filteredRoutes);
  }, [props.routes]);

  return (
    <div className="sidebar" data-color={props.bgColor} data-active-color={props.activeColor}>
      <div className="logo">
        <a href="https://taxokeys.org/" className="simple-text logo-mini" style={{paddingBottom: 0}}>
          <img className="logo-img" src={logo} alt="Taxokeys Logo" />
        </a>
        <a href="https://taxokeys.org/" className="simple-text logo-normal" style={{paddingLeft: '7px'}}>
          TaxoKeys
        </a>
      </div>
      <div className="sidebar-wrapper" ref={sidebarRef}>
        <Nav className="top-nav">
          {adjustedRoutes.map((prop, index) => {
            if (prop.authRequired && !props.isAuthenticated) {
              return null; // Skip rendering this route if auth is required but user isn't authenticated
            }
            return (
              <li className={activeRoute(prop.layout + prop.path) + (prop.pro ? " active-pro" : "")} key={index}>
                <NavLink to={prop.layout + prop.path} className="nav-link">
                  <i className={prop.icon} />
                  <p>{prop.name}</p>
                </NavLink>
              </li>
            );
          })}
        </Nav>
      </div>
    </div>
  );
}

export default Sidebar;
