import redXImage from '../../assets/img/red-x.png';
import TagInput from './TagInput';

const ShowTaxonCharacters = ({ taxon, isEditMode, isLoading, onCharacterChange, onCharacterDelete }) => {
  const handleChange = (e, character) => {
    if (Array.isArray(e.target.value)) {
      onCharacterChange(e.target.value, character);
    } else {
      onCharacterChange(e.target.value, character);
    }
  };

  const handleDelete = (characterName) => {
    onCharacterDelete(characterName);
  };

  const renderCharacter = (characterName, isEditable) => {
    const character = taxon.characters[characterName];
    // Assume all characters will use TagInput
    // Convert character value to array format if it's not already an array
    const selectedValues = Array.isArray(character.value) ? character.value : [character.value].filter(Boolean);

    return (
      <>
        <div style={{display: 'flex', alignItems: 'center'}}>
          {isEditable && (
              <button onClick={() => handleDelete(characterName)} className="red-x">
                  <img src={redXImage} alt="Delete" style={{marginRight: '2px', height: '12px', width: '12px'}} />
              </button>
          )}
          <strong>{character.character_name}: </strong>
        </div>
        {isEditable ? (
            <TagInput
                suggestedValues={character.suggested_values || []} // Assuming all characters can have suggested values or an empty array if not
                selectedValues={selectedValues}
                onTagsChange={(newTags) => handleChange({ target: { value: newTags } }, characterName)}
            />
        ) : (
            <span style={{marginLeft: '4px'}}>{selectedValues.join(', ').toLowerCase()}</span>
        )}
      </>
    );
  };

  const renderGroup = (groupName, groupCharacters, isEditable, index) => {
    return (
      <div key={groupName} className="taxon-character-group" style={{marginTop: index ? "10px" : 0}}>
        <div>
          <h6 style={{marginBottom: 0}}>{groupName}</h6>
        </div>
        <hr style={{marginTop: "0", marginBottom: '8px'}}></hr>
        <div style={{marginTop: '0', borderBottomLeftRadius: '7px', borderBottomRightRadius: '7px'}}> 
            {groupCharacters.map((characterName, i) => (
                <div className="character-container" key={characterName} style={{
                  padding: 0,
                  display: 'flex',
                  marginBottom: i === (groupCharacters.length - 1) ? 0 : '4px'
                }}>
                  {renderCharacter(characterName, isEditable, )}
                </div>
            ))}
        </div>
      </div>
    );
  };

  // Group characters by their "group" entries
  const groupedCharacters = {};
  taxon.characters && (
    Object.keys(taxon.characters).forEach((characterName) => {
        const character = taxon.characters[characterName];
        const groupName = character.group || 'Other'; // Default group if not specified -- shouldn't ever happen, but this is a last resort
        groupedCharacters[groupName] = groupedCharacters[groupName] || [];
        groupedCharacters[groupName].push(characterName);
    })
  )

  // Check if there are characters
  const hasCharacters = Object.keys(groupedCharacters).length > 0;

  return (
    <>
      {hasCharacters ? (
        <>
          {Object.keys(groupedCharacters).sort().map((groupName, index) =>
            renderGroup(groupName, groupedCharacters[groupName], isEditMode, index)
          )}
        </>
      ) : (
        <div style={{display: "flex", alignItems: "center", textAlign: "center", width: "100%", height: "100%"}}>
          <div style={{flex: 1}}>
          No information available for taxon.
          </div>
        </div>
      )}
    </>
  );
};

export default ShowTaxonCharacters;