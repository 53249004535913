import React, { useState, useEffect } from 'react';
import { ProgressBar } from 'react-bootstrap';
import io from 'socket.io-client';

// Connect to the server
const socketUrl = process.env.NODE_ENV === "development" ? "http://localhost:5000" : process.env.REACT_APP_SOCKET_URL;

const socket = io(socketUrl, {
  reconnectionAttempts: 5,
  reconnectionDelay: 1000,
  transports: ['websocket'],
});

const ProgressDisplay = ({ taxonId, sessionId }) => {
  const [progressMessage, setProgressMessage] = useState('');
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (sessionId && taxonId) {

      console.log(`Joining room with sessionId: ${sessionId} and taxonId: ${taxonId}`);

      // Emit sessionId and taxonId when connecting
      socket.emit('joinRoom', { sessionId, taxonId });
  
      // Listen for progress updates from the server
      const handleProgress = (message) => {
        setProgressMessage(message);
        console.log(`Received progress message: ${message}`);
      };
  
      socket.on('progress', handleProgress);
  
      // Handle connection errors
      socket.on('connect_error', (error) => {
        console.error('Connection Error:', error);
      });
  
      // Disable socket upon unmount or re-render
      return () => {
        console.log(`Leaving room with sessionId: ${sessionId} and taxonId: ${taxonId}`);
        socket.off('progress', handleProgress);
        socket.off('connect_error');
        socket.emit('leaveRoom', { sessionId, taxonId });
      };
    }
  }, [taxonId, sessionId]);

  useEffect(() => {
    const messageOrder = [
      "loading_config",
      "connecting_to_database",
      "populating_tree",
      "generating_key",
      "generating_pdf",
      "jsonifying_tree",
      "dumping_json",
      "complete"
    ];

    if (progressMessage) {
      if (progressMessage.startsWith("[error]")) {
        setHasError(true);
        setErrorMessage("Error: " + progressMessage.substring(8))
        console.error(`Python process returned error!`);
      } else {
        setHasError(false);
        const messageIndex = messageOrder.indexOf(progressMessage) + 2;
        setCurrentMessageIndex(messageIndex);
    
        console.log(`Updated currentMessageIndex to ${messageIndex}`);
      }
    }
    
  }, [progressMessage]);

  useEffect(() => {
    setHasError(false);
    if (taxonId) {
      setCurrentMessageIndex(1);
    }
  }, [taxonId]);

  const progressMessages = [
    "Resetting...",
    "Starting...",
    "Loading configuration...",
    "Connecting to database...",
    "Populating tree...",
    "Generating key...",
    "Generating pdf...",
    "Converting to JSON...",
    "Dumping JSON..."
  ];

  return (
    <div>
      {currentMessageIndex < 8 ? (
        <ProgressBar
          variant={hasError ? 'danger' : 'info'}
          label={hasError ? errorMessage : progressMessages[currentMessageIndex] + currentMessageIndex}
          animated now={(currentMessageIndex) / 9 * 100} />
      ) : (
        <ProgressBar variant='success' now={100}></ProgressBar>
      )}
    </div>
  );
};

export default ProgressDisplay;
